@import "src/assets/css/variables";

.view {
  margin-left: 238px;
  transition: 0.5s;
  margin-top: 60px;
  height: calc(100vh - 60px);
  overflow-y: scroll;
}

@media (max-width: $max-mobile-screen-width) {
  .view{
    margin-left: 0 !important;
  }
}