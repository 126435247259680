@import "src/assets/css/variables.scss";
.top {
  > :first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 49px;
    border-bottom: 1px solid #CACACA;
    width: 100%;

    > svg {
      cursor: pointer;
      width: 17px;
      height: 17px;
      margin-right: 15px;
      color: #6A6A6A;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      margin-left: 16px;
    }
  }
  > :nth-child(2) {
    padding: 16px;
  }
}

.table {
  > :first-child {
    display: flex;
    margin: 16px 4px;
    > :first-child {
      width: 202px
    }
    > :nth-child(2) {
      width: 219px;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
    }
  }

  > :nth-child(2) {
    > div {
      display: flex;
      border-top: 0.5px solid #CACACA;
      min-height: 44px;
      align-items: center;

      > span {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        overflow: hidden;

        > span {
          margin-left: 5px;
        }
      }

      > :first-child {
        width: 202px
      }
      > :nth-child(2) {
        width: 219px;
      }
    }
  }
}

@media (max-width: $max-mobile-screen-width) {
  .top .table_row {
  span.invalid_blocks_column {
  width: 150px;
  & > div {
  display: none;
  }
}
span.blocks_percentage_column {
  padding-right: 24px;
}
html[dir="rtl"] span.blocks_percentage_column {
  padding-left: 24px;
}
}
}