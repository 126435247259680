@import "src/assets/css/variables.scss";

.insights {
  margin-top: 28px;

  > h2 {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    margin-left: 28px;
  }

  > div {
    display: flex;
    align-items: center;

    > div {
      background: #fdfbfa;
      box-shadow: 0px 0px 30px rgba(102, 102, 102, 0.05);
      border-radius: 14px;
      width: 100%;
      height: 393px;
      overflow: hidden;

      &:first-child {
        margin-right: 20px;
        max-width: 573px;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      > div {
        &:first-child {
          margin-right: unset;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media (max-width: $max-mobile-screen-width) {
  .insights .threat_types_container {
    height: 700px;
  }
  .insights .threat_groups_container {
    height: 280px;
  }
}
