@import "src/assets/css/variables.scss";

.summary {  
  .content{
    align-items: flex-start;
    min-height: 225px;

  }  
}

@media (max-width: $max-mobile-screen-width){
  .summary h1 {
    font-size: 14px;
    margin-left: 4px;
    margin-bottom: auto;
  }
  .summary .content {
    flex-direction: column;
    box-shadow: 0px 0px 30px rgba(102, 102, 102, 0.05);
    border-radius: 10px;
    gap: 8px;
  };
}