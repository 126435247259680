.modalBody {
  background-color: #fefefe;
  margin: auto;
  border-radius: 4px;
  width: 45%;
  position: relative;

  .modalHeader {
    border-bottom: 0.5px solid #cacaca;
    border-top: 8px solid var(--base-color);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 0px 10px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .closeButton {
      cursor: pointer;
    }
  }
}
.backButton {
  right: 160px;
  bottom: 0;
  margin: 0px 0px 20px 0px;
}
.title {
  padding: 16px 0px 46px 20px;
}
