@import "src/assets/css/variables.scss";

$base-gap: 20px;

.blocksFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  z-index: 1;

  & ~ div{
    z-index: 0;
  }
  
  h2, .section_title {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    margin-left: 8px;
  }
}

.filters-elements{
  display: flex;  
  justify-content: space-between;  
  width: 100%;

  & section{
    display: flex;
    align-items: center;
    & > :not(:first-child){
      margin: 0 12px;
    }

    &:last-child{
      flex-shrink: 0;
    }
  }
}

@media (max-width: $max-mobile-screen-width) {
  .blocksFilters{
    background-color: var(--background-gray);
    padding: 5px 25px 5px 20px;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: unset;
    position: fixed;
    width: 100%;
    height: 43px;
    box-sizing: border-box;
    top: 60px;
    z-index: 0;

    .navbar{
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      .navbar-title{
        color: var(--base-color);
      }
    }

    .search{
      flex-grow: 1;
      justify-content: flex-end;      


      .clear-icon{
        right: 9px;
        top: 7px;
      }

      input{
        padding: 0 30px 0 10px;
      }

      &::before {
        background: none;
      }      
    }
  }

  .loader{
    padding-top: 0;
  }

  .modal{    
    width: 100vw;
    height: calc(100% - var(--nav-heigth));
    box-sizing: border-box;
    background-color: var(--main-white);
    position: fixed;
    top: var(--nav-heigth);
    padding: 12px 16px 20px 16px;    
    display: flex;
    flex-direction: column;
    gap: $base-gap;

    nav{
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }
    }

    & > .filters{
      display: flex;
      flex-direction: column;
      gap: $base-gap;
      padding: 0 16px;
      & > div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;          
        gap: 4px;
        & > div, & > div > input {
          box-sizing: border-box;
          width: 100%;
        }
      }
    }

    footer{
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        gap: $base-gap;
        flex-grow: 1;
  
        button{
          width: 118px;
          height: 36px;
          display: flex;
          justify-content: center;
          gap: 5px;
        }        
    }    
  }
}