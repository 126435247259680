.dataTable {
  height: calc(100vh - 325px);
  background: #FDFBFA;
  box-shadow: 0px 0px 40px rgba(102, 102, 102, 0.05);
  border-radius: 14px;
  padding: 20px;
  overflow: hidden;
  .tableWrapper {
    & > div:first-child{
      display: flex;
      > div {
        display: flex;
      }
    }

    > div { 
      overflow: unset;
    }

    div[role="table"]{
      overflow-y: scroll;
      height: calc(100vh - 427px);
    }
  }
}