@import "src/assets/css/variables.scss";

.sidebar {
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: var(--side-bar-width);
  overflow: hidden;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  background: #FFFFFF;
  box-shadow: 5px 0px 30px rgba(102, 102, 102, 0.08);

  img {
    margin-top: 16px;
    height: 41px;
    width: 160px;
    margin-left: 24px;
  }
  .links {
    width: 206px;
    margin-top: 92px;
    margin-left: 16px;
    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 25px;
      border-top: 0.5px solid #CACACA;

      > button {
        margin-top: 20px;
        width: 129px;
        height: 31px;

        svg {
          margin-right: 4px;
        }
      }
      .addDomainBtn{
        height: unset;
      }
    }

    a {
      padding: 7px 17px;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #282421;
      span {
        margin-left: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
      }

      &:hover {
        color: #FC6723;
        background: #FFF3F0;
        border-radius: 4px;
      }
    }
    .activeLink {
      color: #FC6723;
      background: #FFE3DE;
      border-radius: 4px;
    }
  }
  .sidebar_toogle {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 33px;

    svg {
      margin-right: 28px;
      cursor: pointer;
      color: #FC6723;
    }
  }
}

@media (max-width: $max-mobile-screen-width) {
  .sidebar .sidebar_toogle{
position: absolute;
right: -10px;
top: 20px;
height: 24px;
  }
}