.blocksTable { 
  h2 {
    margin-left: 28px;
  } 

  .body{
    padding: 0;
  } 

  .section{
    margin-top: 0;
  }
}