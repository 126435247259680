.notProtectedIcon {
  @extend .icon-base-class;
  background: url("../../../../assets/images/not_protected_icon.svg") no-repeat;
}

.protectedIcon {
  @extend .icon-base-class;
  background: url("../../../../assets/images/protected_icon.svg");
}

.pluginUpdateIcon {
  @extend .icon-base-class;
  background: url("../../../../assets/images/update_needed_icon.svg");
}

.icon-base-class {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  margin-right: 10px;
  margin-bottom: -3px;
}
.domainStatusButton {
  display: inline-block !important;
  margin-left: 10px;
}
