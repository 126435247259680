.map {
  > :first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 49px;
    border-bottom: 1px solid #CACACA;
    width: 100%;

    > svg {
      cursor: pointer;
      width: 17px;
      height: 17px;
      margin-right: 15px;
      color: #6A6A6A;
    }
    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      margin-left: 16px;
    }
  }
  > :nth-child(2) {
    padding: 16px;
  }
}