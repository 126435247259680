@import "src/assets/css/variables.scss";

.dateSelectionMobile {
  display: none;
}

.dashboard_navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px;
  background-color: var(--main-white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.8em;
  margin-bottom: 28px;

  > * {
    padding: 20px 0;
  }

  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #282421;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    > div {
      label {
        flex-grow: 1;
      }
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
    }
  }

  .refresher_domain_status {
    color: var(--base-color);
    background-color: var(--main-white);
    border: 1px var(--base-color) solid;
  }
  .refresher {
    display: flex;
    align-items: center;
    gap: 1rem;

    button {
      width: 105px;
      .refresher_icon {
        margin-right: 0.25rem;
      }
    }
  }
}

@media (max-width: $max-mobile-screen-width) {
  container {
    flex-direction: column;
  }

  .refresher,
  .filters > div {
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
  }
}
