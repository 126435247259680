.modalBody {
    background-color: #fefefe;
    margin: auto;
    border-radius: 4px;
    width: 75%;
    max-width: 288px;
    position: relative;
    padding: 8px 0px;


    .optionsWrapper{
      margin-top: 8px;
    }
    .optionContainer {
      padding: 0 8px;
      height: 31px;
      position: relative;
      align-items: center;
      &:focus-within, &:hover{
        background-color: #FFE3DE;
      }
      input{
        margin-right: 12px;
        margin-bottom: 4px;
      }
    }

      .modalHeader {
        padding: 0 24px;
        height: 40px;
        font-weight: 600;
        border-bottom: 0.5px solid #cacaca;
    }
  }