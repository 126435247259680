@import "src/assets/css/variables.scss";

$vertival-void: 32px;
$horizontal-void: 16px;
$gap: 10px;

.summary_card{
  box-sizing: border-box;
  padding: $vertival-void $horizontal-void;
  display: flex;
  flex-direction: column;
  background: var(--main-white);
  border-radius: 14px;   
  overflow: hidden;
  width: 100%;  
  color: var(--main-gray);
  &:not(:last-child) {
    margin-right: 26px; 
  }
  
  & > * {
    margin: 0 $horizontal-void;
  }  

  .header{    
    display: flex;
    justify-content: space-between;      
    color: var(--main-black);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;  
    
    .secondary_title{
      font-size: 22px;
      font-weight: 600;
      color: var(--secondary-gray);
    }
  } 

  .body{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;   
    flex: 0 1 100%;
    color: var(--secondary-black);
    font-size: 18px;
    font-weight: 400; 
    .main_content{
      height:100px;
      overflow: hidden;
      text-overflow: ellipsis;
      
      &.heigth_auto{
        height: auto;
      }
    }
  }
  
  .footer{
    .read_more{
      width: -moz-min-content;
      width: min-content;
      white-space: nowrap;
      color: var(--base-color);
      &:hover{
        cursor: pointer;
      }      
    }    
  }
}

@media (max-width: $max-mobile-screen-width) {
  .summary_card h1, .summary_card .header .secondary_title {font-size: 18px;}
  .summary_card .body .main_content {height: fit-content;}
  .footer .read_more {margin-top: 12px;}
  }
