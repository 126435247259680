@import "src/assets/css/variables.scss";

.navbar {
  transition: 0.5s;
  position: absolute;
  display: flex;
  left: var(--side-bar-width);
  width: calc(100% - var(--side-bar-width));
  height: 60px;
  align-items: center;
  background: #FDFBFA;
  box-shadow: 0px 4px 20px rgba(102, 102, 102, 0.08);
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  
  > svg {
    cursor: pointer;
    color: #FC6723;
    transition: 0.5s;
    margin-left: 0;
    width: 0;
  }

  .logout{
    display: flex;
    align-items: center;
    margin-right: 2rem;
    color: var(--main-white);
    background-color: var(--base-color);
    border-radius: 25px;
    padding: 0 10px;
    height: 31px;
    font-size: 14px;
    line-height: 19px;

    &:hover{
      cursor: pointer;
    }

    & > * {
      margin: 0 5px;
    }
  }

  .appSelector {
    margin-left: 2rem;
    display: flex;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    z-index: 3;
    
    span {
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #FC6723;
    }
    ul {
      position: absolute;
      top: 110%;
      left: 0;
      min-width: 165px;
      z-index: 1000;
      padding: 0px 0;
      list-style: none;
      transition: all 0.2s ease;
      background-color: #FDFBFA;
      box-shadow: 0px 5px 30px rgba(102, 102, 102, 0.16);
      border-radius: 10px;
      visibility: hidden;

      li {
        position: relative;
        text-align: left;
        width: 100%;
        margin: 0px;
        display: block;

        a {
          display: block;
          padding: 8px 20px;
          clear: both;
          white-space: nowrap;
          font-weight: 500;
          font-size: 18px;

          &:hover {
            color: #FC6723 !important;
            background: #FFF3F0
          }
        }
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: normal;
          font-size: 12px;
          color: #6A6A6A;
          text-align: center;
          cursor: default;
          img {
            margin-right: 3px;
          }
        }
      }
    }
    &:hover {
      span {
        color: #FE0072;
        > svg {
          cursor: pointer;
          color: #FE0072;
          transition: 0.2s;
          transform: rotate(0.5turn);
        }
      }
      ul {
        visibility: visible;
        opacity: 1;
        margin-top: 0;
        display: block;
      }
    }
  }
}

@media (max-width: $max-mobile-screen-width) {
  div .navbar {
    left: 0;
    width: calc(100%);
    position: fixed;    
  }
}
