@import "src/assets/css/variables.scss";
$base-color: #fc6723;

.modalBody {
  background-color: #fefefe;
  margin: auto;
  border-radius: 4px;
  width: 45%;
  position: relative;
  min-width: 288px;

  .modalHeader {
    border-bottom: 0.5px solid #cacaca;
    border-top: 8px solid $base-color;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 0px 16px 0px 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .closeButton {
      cursor: pointer;
    }
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

@media (max-width: $max-mobile-screen-width) {
  .modalHeader .closeButton {
    position: absolute;
    top: 28px;
    right: 12px;
  }
}


