@import "src/assets/css/variables.scss";

.blocks {
  padding: 28px 20px;
  display: flex;
  flex-direction: column; 
}

@media (max-width: $max-mobile-screen-width) {
  .blocks{
    padding-top: unset;
  }

  .loader{
    overflow: hidden;
  }
}