.traffic_breakdown{
  display: flex;
  .traffic_breakdown_title{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .categories{
    display: flex;
  }
  .category{
    position: relative;
    flex: 1 1 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 15px;

    .total{
      display: flex;
      justify-content: space-between;
      position: relative;
      .arrow_wrapper{
        position: absolute;
        display: flex;
        color: var(--base-color);
        background-color: var(--main-white);
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
        border-radius: 50%;
        right: -76px;
        height: 50px;
        width: 50px;      
        justify-content: center;  
        align-items: center;
      }
    }

    &:not(:last-child){
      position: relative;
      padding-right: 50px;
      border-right: 1px dashed var(--secondary-gray);
    }

    &:not(:first-child){
      padding-left: 50px;
    }

    .header{
      display: flex;
      gap: 10px;
      align-items: center;
    }    
  }
}

.blocked_traffic_breakdown{
  .header {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;

    .header_details section{
      display: flex;
      font-size: 14px;
      font-weight: 400;
    }

    .header_details > span:first-child{
      margin-right: 10px;
    }    
  }    
}
