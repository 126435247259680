$padding: 20px;

.wrapper{
  box-sizing: border-box;
  margin-top: 55px;
  .title{
    font-weight: 700;
    font-size: 14px;
    color: var(--main-black)
  }
  min-height: calc(100vh - 60px);
}

.blocked_ip_title {
  font-weight: 600;
}

.blocked_ip{  
  margin-top: 12px;
  
  &:first-of-type{
    margin-top: 26px;
  }  

  .blocked_ip_body{
    font-size: 14px;
  
    padding: unset;
    & > *  {
      padding: $padding;
    }

    .blocked_ip_details{
      display: grid;
      gap: 8px;
      grid-template-columns: minmax(0, .8fr) minmax(0, 1.2fr);

      .country{
        img{
          border-radius: 25px;
          box-shadow: var(--box-shadow);
        }
      }
    }

    .ellipsis{
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .blocked_ip_url{
      display: grid;
      padding: 12px 20px;
      grid-template-columns: minmax(0, .8fr) minmax(0, 1.2fr);
      border-top: 1px solid var(--tertiary-gray); 
      font-weight: 600;
      .blocked_ip_url_value{
        font-weight: 400;
        color: var(--base-color);
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;      
      }
    }  

    .monitored_status {
      display: grid;
      padding: 12px 20px;
      grid-template-columns: minmax(0, .5fr) minmax(0, 1.2fr);
      border-bottom: 1px solid var(--tertiary-gray); 
      font-weight: 600;
      font-size: 14px;
    }
  }  
}
.no-data{
  display: flex;
  justify-content: center;
  margin: 10px;
  }
    
  .hold-tight{
    margin-top: 0;
    background: transparent;
    box-shadow: unset;
    border: unset;
  }  