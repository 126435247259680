.threat_groups {
  display: flex;
  flex-direction: column;

  > :first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 49px;
    border-bottom: 1px solid #cacaca;
    width: 100%;

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      margin-left: 16px;
    }
    > svg {
      cursor: pointer;
      width: 17px;
      height: 17px;
      margin-right: 15px;
      color: #6a6a6a;
    }
  }
  > :nth-child(2) {
    display: flex;
    align-items: center;
    height: 206px;
    margin-left: 33px;
  }

  .totals {
    margin-left: 48px;
    display: flex;
    flex-direction: column;

    span {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #282421;
      margin: 8px 0;

      div {
        margin-right: 10px;
        width: 14px;
        height: 14px;
        border-radius: 2px;
      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #282421;
      margin: 0;
      margin-top: 20px;
    }
  }
}
.tooltip {  
  opacity: 1 !important;  
  max-width: 500px;
  section {
    display: flex;    
    line-height: 20px;
    flex-direction: column;
    gap: 10px;
  }
}
