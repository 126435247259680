$vertival-void: 32px;
$horizontal-void: 16px;
$gap: 10px;

.section{
  margin-top: 28px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  .title{
    margin-top: 0;
  }
  .title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .body{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;   
    flex: 0 1 100%;
    color: var(--secondary-black);
    font-size: 18px;
    font-weight: 400;   
    padding: 32px 24px;
    background: var(--main-white);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    height: 360px;
  } 
  .warning{
    font-size: 14px;
    color: var( --base-color);
  }
}