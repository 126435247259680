$base-color: #fc6723;
@import "src/assets/css/variables.scss";

.content{
  display: flex;
  flex-direction: column;
  max-width: 864px;
  width: 70vw;
  background-color: white;
  

  & > *{
    padding-left: 30px;
    padding-right: 10px;
    margin-bottom: 20px;
  }

  .header {
    display: flex;
    border-bottom: 0.5px solid #cacaca;
    border-top: 8px solid $base-color;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .body{
    flex-grow: 1;

    .list{
      line-height: 29px;
      padding-inline-start: 20px;
    }

    .codes{
      border-radius: 6px;
      background-color: var(--background-gray);
      width: 60%;
      max-width: 560px;
      margin: auto;

      .copyIcon{
        margin-right: 4px;
      }
      .clipboard_copy{
        line-height: 29px;
        label {
          font-size: 16px;
        }
      }
      button{
        color: $base-color;
        border: transparent;
        font-weight: 600;
        font-size: 12px;
        background-color: transparent;
      }
      .codesData {
        padding: 16px;
        display:flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  
  .footer{
    display: flex;
    justify-content: center;
    align-items: center;
    
    button{
      color: $base-color;
      border: 1px solid $base-color;
      border-radius: 25px;
      font-weight: 600;
      font-size: 12px;
      background-color: transparent;
    }
  }
}

.codesHeader { 
 display: flex;
 height: 40px;
justify-content: space-between;
background: #FFF3F0;
border-radius: 6px 6px 0px 0px;
padding:0 16px;
align-items: center;
font-weight: 700;
}

.codes .setupItem {
  display:flex;
  align-items: center;
  flex-grow: 1;

  > label {
    margin-right: 8px;
  }
}


@media (max-width: $max-mobile-screen-width){  
  .content{
  width: unset;
  background: unset;
  .header{
    flex-direction: column;
    >p{
      margin-bottom: 10px;
    }
    >div {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
  .body{
    height: 370px;
    overflow: scroll;

    .codes .clipboard_copy{
      margin-bottom: 8px;
    }
    .codes .setupItem {    
      flex-direction: column;

     >div:first-of-type {
        width: 100% !important;
      }
    }
    .codes {
      width: unset;
    }
  }
  .codesHeader{
    flex-direction: column;
    height: 55px;
  }
}
}



