.progressbar {
  display: flex;
  align-items: center;
  width: 100px;

  > div {
    flex: 1;
    height: 8px;
    background: #dfdfe7;
    border-radius: 12px;
    overflow: hidden;

    > div {
      height: inherit;
      transition: width 2s ease-in-out;
      background: linear-gradient(90deg, #FFD600, #FE0072);
    }
  }
}