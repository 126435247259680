.modalRoot {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s ease-in-out;
}
.modalRootHidden {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.modalOpen {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
