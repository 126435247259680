.loader {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  & div:first-child {
    position: fixed;
  }
  &.full-screen {
    height: 100vh;
    width: 100vw;
    background-color: var(--tertiary-gray);
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    z-index: 1;
    & > div {
      display: flex;
      align-items: center;
    }
    position: fixed;
  }
}

.loader-wrapper {
  position: relative;
}
