@import "src/assets/css/variables.scss";

.types {
  display: flex;
  padding-right: 14px;
  flex-direction: column;
  flex-grow: 1;

  .header {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    height: 50px;
    border-bottom: 1px solid #cacaca;

    h4 {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
    }

    >div>svg {
      cursor: pointer;
      width: 17px;
      height: 17px;
      color: #6a6a6a;
    }
  }

  .header div:first-child,
  .row>div:first-child {
    max-width: 299px;
    min-width: 230px;
  }

  .body {
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &.flex_column{
      display: flex;
      flex-direction: column;

      .bar_chart_wrapper{
        width: 50%;
      }
    }
  }
}

.group-color {
  margin-right: 10px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
}

.chartWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

 @media (max-width: $max-mobile-screen-width) {
  .types .body{
    flex-direction: column;
    gap: 20px;
  }
 }
