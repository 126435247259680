@import "src/assets/css/variables.scss";

.trigger {
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    width: 17px;
    height: 17px;
    color: var(--main-gray);
  }
}

.tooltip {
  opacity: 1 !important;
  width: 300px;
  box-shadow: var(--box-shadow);
  opacity: 1 !important;

  .body {
    min-width: 200px;
    max-width: 300px;
    max-height: 300px;
    overflow-y: scroll;
    line-height: 20px;
    font-weight: 400;
    
    &::-webkit-scrollbar {
      display: none;
    }

    .toggle_view {
      text-decoration: underline;
      color: var(--secondary-color);
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}
@media (max-width: $max-mobile-screen-width) {
  .tooltip {width: 200px;}
}