@import "src/assets/css/variables.scss";

.countries{
  margin-top: 20px;

  > div {
    display: flex;
    align-items: center;

    > div {
      background: #FDFBFA;
      box-shadow: 0px 0px 30px rgba(102, 102, 102, 0.05);
      border-radius: 14px;
      width: 100%;
      height: 566px;
      overflow: hidden;

      &:first-child {
        margin-right: 20px;
        max-width: 573px;
      }
    }
  }
}

@media (max-width: $max-mobile-screen-width) {
  .countries > div {flex-direction: column;
    gap: 16px;
    > div:first-child {margin-right: 0;}
  }
  .countries >div > div{
    height: unset
  }
}