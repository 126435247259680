.donut {
   margin-top: 5px;
   width: 170px;
}

.heatmap {
   border: 1px solid #9B9B9B;
   box-sizing: border-box;
   border-radius: 6px;
   overflow: hidden;
}